<template>
  <div v-if="userModel">
    <div class="fx fx-start align-center title">
      <h1>
        Клиент {{ userModel.login }}
        <span v-if="!userModel['is_active']" class="text-danger">
          (заблокирован)
        </span>
      </h1>
      <div class="ml-20">
        <vue-spinner color="#1890ffb2" v-if="showLoader"></vue-spinner>
      </div>
    </div>
    <v-notification>
    </v-notification>
    <div class="row my-3">
      <div class="col-6 col-xl-2 col-lg-2 col-md-3 col-sm-6 mb-2">
        <button
          :class="'btn-t btn--borderRed flex-center' + (showPanelType === 1 ? ' btn--borderRed_hover' : '')"
          @click="showPanelType = 1">
          Основная информация
        </button>
      </div>
      <div class="col-6 col-xl-2 col-lg-2 col-md-3 col-sm-6 mb-2">
        <button
          :class="'btn-t btn--borderRed flex-center' + (showPanelType === 2 ? ' btn--borderRed_hover' : '')"
          @click="getBalanceHistory">
          История баланса
        </button>
      </div>
    </div>

    <template v-if="showPanelType === 1">
      <div class="row">
        <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <div class="row">

            <div class="col-3 form__field">
              <label id="id">
                <span class="form__field-span">ID:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <input id="id" readonly type="text" :placeholder="userModel.id"/>
            </div>


            <div class="col-3 form__field">
              <label id="login">
                <span class="form__field-span">Логин:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <input id="login" readonly type="text" :placeholder="userModel.login"/>
            </div>

            <div class="col-3 form__field">
              <label id="email">
                <span class="form__field-span">Email:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <input id="email" type="text" v-model="userModel.email"/>
            </div>

            <div class="col-3 form__field">
              <label id="created_at">
                <span class="form__field-span">Зареган:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <input id="created_at" readonly type="text" :placeholder="userModel['created_at']"/>
            </div>

            <div class="col-3 form__field">
              <label id="updated_at">
                <span class="form__field-span">Обновлен:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <input id="updated_at" readonly type="text" :placeholder="userModel['updated_at']"/>
            </div>

            <div class="col-3 form__field">
              <label>
                <span class="form__field-span">IP авторизации:</span>
              </label>
            </div>
            <div class="col-9 form__field" style="display: block">
              <div>
                <input id="updated_at" readonly type="text" :placeholder="getIpPlaceholder"/>
              </div>
              <button @click="toggleTable">
                <span v-if="!showTable">&#9660;</span>
                <span v-else>&#9650;</span>
              </button>
              <table v-if="showTable" class="ip-table">
                <thead>
                <tr>
                  <th>IP</th>
                  <th>Дата и время</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(entry, index) in userModel['authorization_history']" :key="index">
                  <td>{{ entry.ip }}</td>
                  <td>{{ entry.created_at }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="col-3 form__field">
              <label id="email_verified_at">
                <span class="form__field-span">Дата верификации:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <input id="email_verified_at"
                     readonly
                     type="text"
                     :class="(userModel['email_verified_at'] ? 'green' : 'red')"
                     :value="(userModel['email_verified_at'] ? userModel['email_verified_at'] : 'Клиент не верифицирован')"
              />
            </div>
            <div class="col-3 form__field">
              <label id="roles">
                <span class="">Роли:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <multiselect id="roles"
                           label="name"
                           track-by="id"
                           v-model="userModel['roles']"
                           :options="constantRoles"
                           :placeholder="userModel.hasOwnProperty('roles') ? userModel['roles'].map((e) => e.name).join(', ') : 'Ролей нет'"
                           :closeOnSelect="false"
                           :multiple="true"
                           openDirection="bottom"
                           style="width: 100%"
              ></multiselect>
            </div>
            <div class="col-3 form__field">
              <label id="password">
                <span class="form__field-span">Блокировка: </span>
              </label>
            </div>
            <div class="col-9 form__field">
              <label class="switch">
                <input type="checkbox"
                       placeholder="active"
                       v-model="isBlocked"
                >
                <span class="slider round"></span>
              </label>
            </div>

            <div class="col-3 form__field">
              <label id="password">
                <span class="form__field-span">Пароль:</span>
              </label>
            </div>
            <div class="col-9 form__field">
              <input id="password" type="password" v-model="newPassword" placeholder="Новый пароль"/>
            </div>

            <div class="fx fx-center mb-20">
              <button class="btn-t btn--borderRed flex-center col-4" @click="updateUser">
                Сохранить данные
              </button>
              <button v-if="!userModel['email_verified_at']"
                      class="btn-t btn--borderRed flex-center col-4 ml-20"
                      @click="verificateUser">
                Верифицировать юзера
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <div class="align-start">
            <h3 class="my-2">Управление балансом</h3>
            <label class="form__field col-12">
              <span class="form__field-span">Резерв:</span>
              <input readonly type="text" :value="userModel['reserved_balance']"/>
            </label>
            <label class="form__field col-12">
              <span class="form__field-span">Баланс:</span>
              <input readonly type="text" :class="userModel['balance'] > 0 ? 'green' : 'red'"
                     :value="new Intl.NumberFormat('ru-RU',{style:'currency',currency:'RUB'}).format(userModel['balance'])"/>
            </label>
            <div class="row">
              <div class="col-7">
                <label class="form__field">
                  <input type="text" placeholder="Добавить (нужно ввести и нажать enter)"
                         v-model="changeBalanceModel"
                         @keyup.enter="changeBalance(changeBalanceModel)"
                  />
                </label>
              </div>
              <div class="col-5">
                <button class="btn-t btn--borderRed flex-center "
                        @click="changeBalance(changeBalanceModel)">
                  Изменить баланс
                </button>

              </div>
              <div class="col-7">
                <label class="form__field">
                  <input type="text" placeholder="Коментарий к пополнению"
                         v-model="paymentComment"
                  />
                </label>
              </div>
            </div>
            <h3>Изменение токена</h3>
            <label class="form__field col-12 mt-20">
              <span class="form__field-span">Token:</span>
              <input readonly type="text" :value="userModel['api_token']"/>
            </label>
            <button class="btn-t btn--borderRed flex-center col-12" @click="updateUserToken">
              Обновить токен
            </button>
          </div>
        </div>
      </div>
    </template>
    <div :class="'col-12' + (showPanelType !== 2 ? ' hidden' : '')">
      <vue-good-table
        title="Balance history"
        :columns="columnsBalanceHistory"
        :rows="balanceHistories"
        :lineNumbers="false"
        :sort-options="{
            enabled: true,
          }"
        :style-class="'vgt-table admin-table'"
        :globalSearch="true"
        :pagination-options="{
            enabled: true,
            perPage: perPage
          }"
      >
        <template v-slot:table-row="props">
          <div class="fx fx-start align-center" style="font-size: 14px; padding: 10px">
            <template v-if="props.column.field === 'change'">
                <span :class="props.row[props.column.field] > 0 ? 'green' : 'red'">{{
                    new Intl.NumberFormat('ru-RU', {
                      style: 'currency',
                      currency: 'RUB'
                    }).format(props.row[props.column.field])
                  }}<br></span>
            </template>
            <template v-else>
              <span>{{ props.formattedRow[props.column.field] }}</span>
            </template>
          </div>
        </template>
        <template v-slot:pagination-bottom="props">
          <custom-pagination
            :total="props.total"
            :pageChanged="props.pageChanged"
            :perPageChanged="props.perPageChanged"
            :perPage="perPage"
          >
          </custom-pagination>
        </template>
      </vue-good-table>
    </div>
    <div :class="'col-12' + (showPanelType !== 5 ? ' hidden' : '')">
      <div class="row fx fx-wrap">
        <!-- Col -->
        <div class="col-4">
          <div class="filter__group">
            <label class="form__field form__field-date"><input type="text" placeholder="Дата (от)"
                                                               v-model="filterDateCreatedFrom"
                                                               onfocus="(this.type='datetime-local')"
                                                               onblur="(this.type='text')"/></label>
            <label class="form__field form__field-date"><input type="text" placeholder="Дата (до)"
                                                               v-model="filterDateCreatedTo"
                                                               onfocus="(this.type='datetime-local')"
                                                               onblur="(this.type='text')"/></label>
          </div>
          <multiselect
            v-model="filterStatuses"
            placeholder="Статусы"
            label="code"
            track-by="code"
            :closeOnSelect="false"
            :options="statuses"
            :multiple="true"
            openDirection="bottom"
          ></multiselect>
        </div>
        <!-- Col -->
        <div class="col-4">
          <div class="filter__group">
            <label class="form__field">
              <input type="text" placeholder="Время (от)" v-model="filterTimeFrom"/>
            </label>
            <label class="form__field">
              <input type="text" placeholder="Время (до)" v-model="filterTimeTo"/>
            </label>
          </div>
        </div>
        <!-- Col -->
        <div class="col-4">
          <label class="form__field">
            <input type="text" placeholder="Поиск по запросу/ответу" v-model="filterSearch"/>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomPagination from "../../../components/customPagination";
import BaseAdminMixin from "../mixins/BaseAdminMixin";
import Multiselect from "vue-multiselect";
import Vue from "vue";

export default {
  name: "userCard",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    CustomPagination,
    Multiselect
  },
  props: ['user'],
  data: function () {
    return {
      userModel: {},
      oldUserModel: {},
      newPassword: null,
      changeBalanceModel: null,
      paymentComment: '',
      perPage: 25,
      columnsBalanceHistory: [
        {
          label: 'Дата',
          field: 'created_at',
          width: '15%',
          sortable: true,
        },
        {
          label: 'Тип операции',
          field: 'type_id',
          width: '19%',
          sortable: false,
        },
        {
          label: 'Комментарий',
          field: 'description',
          width: '25%',
          sortable: false,
        },
        // {
        //   label: 'Активация',
        //   field: 'phone_activations',
        //   width: '25%',
        //   sortable: false,
        // },
        {
          label: 'Менеджер',
          field: 'manager',
          width: '25%',
          sortable: false,
        },
        {
          label: 'Сумма',
          field: 'change',
          type: 'number',
          width: '14%',
        },
      ],
      columnsPhoneActivations: [
        {
          label: 'Дата',
          field: 'created_at',
          sortable: true,
          width: '15%',
        },
        {
          label: 'Статус',
          field: 'status',
          sortable: false,
          width: '15%',
        },
        {
          label: 'Сервис, страна, провайдер',
          field: 'provider_service_variation',
          sortable: false,
          html: true,
          width: '30%',
        },
        {
          label: 'Телефон',
          field: 'phone',
          sortable: false,
          width: '10%',
        },
        {
          label: 'СМС',
          field: 'sms_code',
          sortable: false,
          width: '10%',
        },
        {
          label: 'Цена⠀⠀',
          field: 'price',
          type: 'number',
          width: '10%',
        },
        {
          label: 'Закупочная цена',
          field: 'wholesale_price',
          type: 'number',
          width: '10%',
        },
      ],
      showPanelType: 1,
      filterDateCreatedFrom: null,
      filterDateCreatedTo: null,
      filterTimeFrom: null,
      filterTimeTo: null,
      filterStatuses: [],
      filterSearch: null,
      requestLogsFiltered: [],
      roles: [],
      constantRoles: [],
      isBlocked: false,
      balanceHistories: [],
      statuses: [],
      showTable: false,
    }
  },
  async mounted() {
    await this.updateRoleSelector();
    this.userModel = this.user;
    this.oldUserModel = Object.assign({}, this.userModel);
    this.isBlocked = !this.userModel['is_active'];
  },

  methods: {
    async getBalanceHistory() {
      if (this.showPanelType !== 2) {
        this.showPanelType = 2;
        let getBalanceHistory = await this.sendRequest('/users/detailBalanceHistory/' + this.userModel['id'])
        let array = [];
        for (let history of getBalanceHistory) {
          array.push({
            created_at: history['created_at'],
            type_id: 123,
            description: history['payment']['description']?? '',
            phone_activation: history['phone_activation'] ? history['phone_activation']['phone_num'] : '',
            change: history['change'],
          });
        }
        this.balanceHistories = array;
      }
    },
    async updateRoleSelector() {
      await this.sendRequest('/users/getRoles', 'get').then(response => {
        for (let elem in response) {
          this.constantRoles.push(response[elem]);
        }
      });
    },
    verificateUser: async function () {
      let self = this;
      await this.doWithLoader(() => self.doWithStopRequest(
        async () => {
          let data = {
            id: self.userModel.id,
            verification: true,
          }
          let result = await self.sendRequest('/users/updateUser', 'post', data);
          if (result) {
            self.oldUserModel = Object.assign({}, self.userModel);
            self.userModel = result;
            await self.alertMessage('Клиент успешно верифицирован', 'good');
          }
        }
      ))
    },

    updateUser: async function () {
      let self = this;
      await this.doWithLoader(() => self.doWithStopRequest(
        async () => {
          let data = {id: self.userModel.id};
          if (self.userModel.email != self.oldUserModel.email) {
            data.email = self.userModel.email;
          }
          if (self.newPassword) {
            data.password = self.newPassword;
          }
          data.block = this.isBlocked;

          let oldRoles = self.oldUserModel.roles.map((elem) => elem.name);
          let newRoles = self.userModel.roles.map((elem) => elem.name);
          if (JSON.stringify(oldRoles) != JSON.stringify(newRoles)) {
            let resultChangeRole = await self.sendRequest('/users/updateUserRole', 'post', {id: self.userModel.id, newRoles});
            if (resultChangeRole) {
              self.oldUserModel = Object.assign({}, self.userModel);
              self.userModel = resultChangeRole;
              self.newPassword = null;
              await self.alertMessage('Роль успешно обновлена', 'good');
            }
            return 1;
          }

          let result = await self.sendRequest('/users/updateUser', 'post', data);
          if (result) {
            self.oldUserModel = Object.assign({}, self.userModel);
            self.userModel = result;
            self.newPassword = null;
            await self.alertMessage('Клиент успешно обновлен', 'good');
          }
        }
      ))
    },

    updateUserToken: async function () {
      let self = this;
      await this.doWithLoader(() => self.doWithStopRequest(
        async () => {
          let data = {
            id: self.userModel.id,
            updateToken: true,
          }
          let result = await self.sendRequest('/users/updateUser', 'post', data);
          if (result) {
            self.oldUserModel = Object.assign({}, self.userModel);
            self.userModel = result;
            await self.alertMessage('Токен успешно обновлен', 'good');
          }
        }
      ))
    },

    changeBalance: async function (change) {
      let self = this;
      if(!change){
        await self.alertMessage('Введите баланс', 'error');
        return false;
      }
      if (!this.paymentComment) {
        await self.alertMessage('Введите Описание', 'error');
        return false;
      }
      change = change.replace(/,/g, "");
      await this.doWithLoader(() => self.doWithStopRequest(
        async () => {
          let data = {
            id: self.userModel.id,
            change: change,
            description: this.paymentComment
          }
          let result = await self.sendRequest('/users/changeUserBalance', 'post', data);
          if (result) {
            self.oldUserModel = Object.assign({}, self.userModel);
            self.userModel = result;
            self.changeBalanceModel = null;
            this.paymentComment = '';
            await self.alertMessage('Баланс обновлен', 'good');
          }
        }
      ))
    },
    toggleTable() {
      this.showTable = !this.showTable;
    },
  },
  computed: {
    getIpPlaceholder() {
      if (this.userModel['authorization_history'] && this.userModel['authorization_history'][0]?.ip) {
        return this.userModel['authorization_history'][0]?.ip;
      }
      return '';
    }
  },

  watch: {
    changeBalanceModel: function (newValue) {
      if (newValue) {
        let result = newValue.replace(/[^-.0-9]+/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        Vue.nextTick(() => this.changeBalanceModel = result);
      }
    },
    filterSearch: function (val) {
      if (val === '') this.filterSearch = null;
      this.updateRequestLogLisByFilterWithPause();
    },

    filterDateCreatedTo: function (val) {
      if (val === '') this.filterDateCreatedTo = null;
      this.updateRequestLogLisByFilterWithPause();
    },

    filterDateCreatedFrom: function (val) {
      if (val === '') this.filterDateCreatedFrom = null;
      this.updateRequestLogLisByFilterWithPause();
    },

    filterTimeFrom: function (val) {
      if (val === '') this.filterTimeFrom = null;
      this.updateRequestLogLisByFilterWithPause();
    },

    filterTimeTo: function (val) {
      if (val === '') this.filterTimeTo = null;
      this.updateRequestLogLisByFilterWithPause();
    },

    filterStatuses: function (val) {
      this.updateRequestLogLisByFilterWithPause();
    },
  }
}
</script>

<style scoped>
.ip-table {
  border-collapse: collapse;
}

.ip-table th,
.ip-table td {
  padding: 8px;
  border: 1px solid #ddd;
}
</style>
