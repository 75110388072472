<template>
  <div>
    <!-- Title -->
    <div class="title">
      <h1>Клиенты</h1>
    </div>
    <v-notification>
    </v-notification>
    <template v-if="list.length">
      <!-- Buttons -->
      <div class="topping fx">
        <vue-spinner color="#1890ffb2" v-if="showLoader"></vue-spinner>
        <div class="topping__panel fx align-center">
          <div class="form search">
            <label class="form__field"><input type="text" placeholder="Поиск" v-model="filterSearch"/></label>
          </div>
          <div class="topping__btns fx">
            <button v-if="isListFiltered" class="btn-t btn--borderRed flex-center" @click="resetFilters">Сбросить
              <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.976746 20C0.783574 20 0.59473 19.9428 0.434101 19.8355C0.273473 19.7282 0.148276 19.5756 0.0743471 19.3972C0.000418327 19.2187 -0.0189212 19.0223 0.0187748 18.8329C0.0564708 18.6434 0.149509 18.4694 0.28612 18.3328L18.333 0.285944C18.5162 0.102779 18.7646 -0.00012207 19.0236 -0.00012207C19.2827 -0.00012207 19.5311 0.102779 19.7143 0.285944C19.8974 0.469109 20.0003 0.717535 20.0003 0.976569C20.0003 1.2356 19.8974 1.48403 19.7143 1.66719L1.66737 19.7141C1.57676 19.8049 1.46911 19.8769 1.35059 19.9259C1.23207 19.975 1.10502 20.0002 0.976746 20Z"/>
                <path
                    d="M19.0236 20C18.8953 20.0002 18.7682 19.975 18.6497 19.9259C18.5312 19.8769 18.4235 19.8049 18.3329 19.7141L0.286066 1.66719C0.102901 1.48403 0 1.2356 0 0.976569C0 0.717535 0.102901 0.469109 0.286066 0.285944C0.469231 0.102779 0.717657 -0.00012207 0.976691 -0.00012207C1.23573 -0.00012207 1.48415 0.102779 1.66732 0.285944L19.7142 18.3328C19.8508 18.4694 19.9438 18.6434 19.9815 18.8329C20.0192 19.0223 19.9999 19.2187 19.926 19.3972C19.852 19.5756 19.7268 19.7282 19.5662 19.8355C19.4056 19.9428 19.2167 20 19.0236 20Z"/>
              </svg>
            </button>
            <button class="btn-t btn--borderRed flex-center" v-if="showFilters === true" @click="showFilters = false">
              Свернуть
              <svg viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.24181 5.60334V0.967742C4.24181 0.433468 3.80866 0 3.27407 0C2.73948 0 2.30633 0.433468 2.30633 0.967742V5.60338C1.25439 5.9979 0.5 7.00558 0.5 8.19367C0.5 9.38177 1.25439 10.3894 2.30633 10.7839V19.0323C2.30633 19.5665 2.73948 20 3.27407 20C3.80866 20 4.24181 19.5665 4.24181 19.0323V10.784C5.29386 10.3894 6.04845 9.38181 6.04845 8.19367C6.04845 7.00554 5.29386 5.99787 4.24181 5.60334Z"/>
                <path
                    d="M11.4674 9.81835V0.967742C11.4674 0.433468 11.0342 0 10.4997 0C9.96506 0 9.53191 0.433468 9.53191 0.967742V9.81835C8.47998 10.2129 7.72559 11.2205 7.72559 12.4086C7.72559 13.5967 8.47998 14.6044 9.53191 14.9989V19.0323C9.53191 19.5665 9.96506 20 10.4997 20C11.0342 20 11.4674 19.5665 11.4674 19.0323V14.9989C12.5194 14.6045 13.2737 13.5968 13.2737 12.4086C13.2737 11.2205 12.5194 10.2128 11.4674 9.81835Z"/>
                <path
                    d="M18.6938 5.60338V0.967742C18.6938 0.433468 18.2606 0 17.726 0C17.1915 0 16.7583 0.433468 16.7583 0.967742V5.60334C15.7063 5.99787 14.9517 7.00554 14.9517 8.19367C14.9517 9.38181 15.7063 10.3894 16.7583 10.784V19.0323C16.7583 19.5665 17.1915 20 17.726 20C18.2606 20 18.6938 19.5665 18.6938 19.0323V10.784C19.7458 10.3895 20.5001 9.38181 20.5001 8.19367C20.5001 7.00554 19.7458 5.99787 18.6938 5.60338Z"/>
              </svg>
            </button>
            <button class="btn-t btn--borderRed flex-center" v-else @click="showFilters = true">Развернуть
              <svg viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4.24181 5.60334V0.967742C4.24181 0.433468 3.80866 0 3.27407 0C2.73948 0 2.30633 0.433468 2.30633 0.967742V5.60338C1.25439 5.9979 0.5 7.00558 0.5 8.19367C0.5 9.38177 1.25439 10.3894 2.30633 10.7839V19.0323C2.30633 19.5665 2.73948 20 3.27407 20C3.80866 20 4.24181 19.5665 4.24181 19.0323V10.784C5.29386 10.3894 6.04845 9.38181 6.04845 8.19367C6.04845 7.00554 5.29386 5.99787 4.24181 5.60334Z"/>
                <path
                    d="M11.4674 9.81835V0.967742C11.4674 0.433468 11.0342 0 10.4997 0C9.96506 0 9.53191 0.433468 9.53191 0.967742V9.81835C8.47998 10.2129 7.72559 11.2205 7.72559 12.4086C7.72559 13.5967 8.47998 14.6044 9.53191 14.9989V19.0323C9.53191 19.5665 9.96506 20 10.4997 20C11.0342 20 11.4674 19.5665 11.4674 19.0323V14.9989C12.5194 14.6045 13.2737 13.5968 13.2737 12.4086C13.2737 11.2205 12.5194 10.2128 11.4674 9.81835Z"/>
                <path
                    d="M18.6938 5.60338V0.967742C18.6938 0.433468 18.2606 0 17.726 0C17.1915 0 16.7583 0.433468 16.7583 0.967742V5.60334C15.7063 5.99787 14.9517 7.00554 14.9517 8.19367C14.9517 9.38181 15.7063 10.3894 16.7583 10.784V19.0323C16.7583 19.5665 17.1915 20 17.726 20C18.2606 20 18.6938 19.5665 18.6938 19.0323V10.784C19.7458 10.3895 20.5001 9.38181 20.5001 8.19367C20.5001 7.00554 19.7458 5.99787 18.6938 5.60338Z"/>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <!-- Filters -->
      <div v-if="showFilters" class="form filter">
        <div class="row fx fx-wrap">
          <!-- Col -->
          <div class="col-4">
            <multiselect
              v-model="filterUsers"
              placeholder="Клиенты"
              label="login"
              track-by="id"
              :closeOnSelect="false"
              group-values="list"
              group-label="group"
              :group-select="true"
              :showLabels="false"
              :options="wrapperForAllSelected(list)"
              :multiple="true"
              openDirection="bottom"
            ></multiselect>
            <div class="filter__group">
              <label class="form__field form__field-date"><input type="text" placeholder="Дата (от)"
                                                v-model="filterDateCreatedFrom" onfocus="(this.type='datetime-local')"
                                                onblur="(this.type='text')"/></label>
              <label class="form__field form__field-date"><input type="text" placeholder="Дата (до)"
                                                v-model="filterDateCreatedTo" onfocus="(this.type='datetime-local')"
                                                onblur="(this.type='text')"/></label>
            </div>
          </div>
          <!-- Col -->
          <div class="col-4">
            <div class="filter__group">
              <label class="form__field">
                <input type="text" placeholder="Баланс (от)" v-model="filterBalanceFrom"/>
              </label>
              <label class="form__field">
                <input type="text" placeholder="Баланс (до)" v-model="filterBalanceTo"/>
              </label>
            </div>
          </div>
          <!-- Col -->
          <div class="col-4">

          </div>
        </div>
      </div>
      <!-- Table -->
      <vue-good-table
          title="Service variations"
          :columns="columns"
          :rows="filteredList"
          :lineNumbers="false"
          :sort-options="{
            enabled: true,
          }"
          :style-class="'vgt-table admin-table'"
          :globalSearch="true"
          :pagination-options="{
            enabled: true,
            perPage: perPage,
            dropdownAllowAll: false,
            perPageDropdown: [25, 50, 100, 300],
          }"
      >
        <template v-slot:table-actions-bottom>
          <div class="fx fx-start fx-wrap mt-40">
            <label class="form__field mr-10 col-12 col-lg-2">
              <input type="text" placeholder="Логин" autocomplete="off" v-model="newUserModel.login"/>
            </label>
            <label class="form__field mr-10 col-12 col-lg-2">
              <input type="text" placeholder="Email" autocomplete="off" v-model="newUserModel.email"/>
            </label>
            <label class="form__field mr-10 col-12 col-lg-2">
              <input type="password" placeholder="Пароль" autocomplete="off" v-model="newUserModel.password"/>
            </label>
            <label class="form__field mr-10 col-12 col-lg-1">
              <input type="text" placeholder="Баланс" autocomplete="off" v-model="newUserModel.balance"/>
            </label>
            <button class="btn-t btn--borderRed flex-center col-12 col-lg-2" v-on:click="createUser">Создать клиента
            </button>
          </div>
        </template>
        <template slot="table-column" slot-scope="props">
          <div class="fx align-center">
            <span>
              {{ props.column.label }}
            </span>
          </div>
        </template>
        <template v-slot:table-row="props">
          <a v-if="props.column.field !== 'auth'" :href="'/users/' + props.row.id">
            <div :class="'admin-table-element' + (!props.row['is_active'] ? ' text-danger' : '') ">
              <template v-if="props.row.hasOwnProperty('roles') && props.column.field === 'roles'">
                {{ props.row['roles'].map(function (elem) { return elem.name }).join(', ') }}
              </template>
              <template v-else-if="props.column.field === 'balance' || props.column.field === 'reserved_balance' ">
                <span :class="props.row[props.column.field] > 0 ? 'green' : 'red'">{{ new Intl.NumberFormat('ru-RU',{style:'currency',currency:'RUB'}).format(props.row[props.column.field]) }}<br></span>
              </template>
              <template v-else>
                {{ props.formattedRow[props.column.field] }}
              </template>
            </div>
          </a>
          <template v-else>
            <a class="btn btn-danger" :href="'/users/authorizeByUser/' + props.row.id">
              Авторизоваться
            </a>
          </template>
        </template>
      </vue-good-table>
    </template>
  </div>
</template>

<script>
import CustomPagination from "../../../components/customPagination";
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import '../../../assets/css/multiselectCustomization.css';
import BaseAdminMixin from "../mixins/BaseAdminMixin";

export default {
  name: "usersTable",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    CustomPagination,
    Multiselect
  },
  data: function () {
    return {
      list: [],
      filteredList: [],
      columns: [
        {
          label: 'ID⠀⠀',
          field: 'id',
          type: 'number',
          width: '5%',
        },
        {
          label: 'Логин⠀⠀',
          field: 'login',
          width: '10%',
        },
        {
          label: 'Email',
          field: 'email',
          width: '15%',
        },
        {
          label: 'Дата регистрации',
          field: 'created_at',
          width: '18%',
        },
        {
          label: 'Баланс⠀⠀',
          field: 'balance',
          type: 'number',
          width: '10%',
        },
        {
          label: 'Резерв⠀⠀',
          field: 'reserved_balance',
          type: 'number',
          width: '10%',
        },
        {
          label: 'Роли⠀⠀',
          field: 'roles',
          width: '18%',
        },
        {
          label: 'Auth⠀⠀',
          field: 'auth',
          width: '10%',
        },
      ],
      showFilters: true,
      perPage: 25,
      timeout: null,
      filterSearch: null,
      filterUsers: [],
      filterDateCreatedFrom: null,
      filterDateCreatedTo: null,
      filterBalanceFrom: null,
      filterBalanceTo: null,
      newUserModel: {
        login: null,
        email: null,
        password: null,
        balance: null,
      },
    };
  },
  async mounted() {
    await this.loadUsers();
  },

  methods: {
    async loadUsers() {
      let users = await this.sendRequest('/users/getUsers/');

      this.list = users;
      this.filteredList = users;
    },

    async updateByFiltersWithPause() {
      clearTimeout(this.timeout);
      let self = this;
      this.timeout = setTimeout(
          () => self.doWithStopRequest(() => self.doWithLoader(() => self.updateByFilters()), 'Фильтрация'),
          1
      );
    },

    async updateByFilters() {
      let filteredList = [];
      let usersIDs = this.filterUsers.map(function (value, index) {
        return value.id;
      });

      let filterDateUpdatedFrom = null;
      let filterDateUpdatedTo = null;
      if (this.filterDateCreatedFrom) {
        filterDateUpdatedFrom = this.filterDateCreatedFrom.replace('T', ' ');
      }
      if (this.filterDateCreatedTo) {
        filterDateUpdatedTo = this.filterDateCreatedTo.replace('T', ' ');
      }

      for (let item of this.list) {
        if (this.filterSearch) {
          let regExp = new RegExp(this.filterSearch, "i");
          if ((item.login + '||||' + item.email).search(regExp) === -1) {
            continue;
          }
        }

        if ((this.filterBalanceFrom !== null) && Number(item['balance']) < this.filterBalanceFrom) {
          continue;
        }

        if ((this.filterBalanceTo !== null) && Number(item['balance']) > this.filterBalanceTo) {
          continue;
        }

        if (usersIDs.length && usersIDs.indexOf(item['id']) === -1) {
          continue;
        }

        if (filterDateUpdatedFrom && item['created_at'] < filterDateUpdatedFrom) {
          continue;
        }

        if (filterDateUpdatedTo && item['created_at'] > filterDateUpdatedTo) {
          continue;
        }

        filteredList.push(item);
      }

      if (filteredList.length === 0) {
        this.filteredList = [];
        await this.alertMessage('Вариаций при таких фильтрах не найдено', 'error')
      } else {
        this.filteredList = filteredList;
      }
    },

    async createUser() {
      let result = await this.sendRequest(
          '/users/createUser',
          'post',
          this.newUserModel
      );

      if (result) {
        await this.alertMessage('Клиент успешно создан', 'good');
        this.list.unshift(result);
      }
    },

    async resetFilters() {
      this.filterSearch = null;
      this.filterUsers = [];
      this.filterDateCreatedFrom = null;
      this.filterDateCreatedTo = null;
      this.filterBalanceTo = null;
      this.filterBalanceFrom = null;
    },
    wrapperForAllSelected: function (data) {
      return [
        {
          group: 'Все',
          list: data
        },
      ];
    }
  },

  computed: {
    isListFiltered: function () {
      return (this.filterSearch !== null)
          || this.filterUsers.length
          || (this.filterDateCreatedFrom !== null)
          || (this.filterDateCreatedTo !== null)
          || (this.filterBalanceFrom !== null)
          || (this.filterBalanceTo !== null);
    }
  },

  watch: {
    filterUsers: function (val) {
      this.updateByFiltersWithPause();
    },

    filterSearch: function (val) {
      if (val === '') this.filterSearch = null;
      this.updateByFiltersWithPause();
    },

    filterDateCreatedTo: function (val) {
      if (val === '') this.filterDateCreatedTo = null;
      this.updateByFiltersWithPause();
    },

    filterDateCreatedFrom: function (val) {
      if (val === '') this.filterDateCreatedFrom = null;
      this.updateByFiltersWithPause();
    },

    filterBalanceFrom: function (val) {
      if (val === '') this.filterBalanceFrom = null;
      this.updateByFiltersWithPause();
    },

    filterBalanceTo: function (val) {
      if (val === '') this.filterBalanceTo = null;
      this.updateByFiltersWithPause();
    },

    list: function (val) {
      this.updateByFiltersWithPause();
    }
  }
}
</script>

<style scoped>

</style>
