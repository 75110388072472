<template>
  <div>
    <modal-add v-if="enableModalAdd"
               :addModalInputData="addModalInputData"
               :addModalUrl="addModalUrl"
               @insert-added-data="pasteNewAddModalItems"
               ref="modalComponent">
    </modal-add>
    <wrapper-filters-component
      v-if="enabledFilters"
      :title="filterTitle"
      :filtersUrl="filtersUrl"
      :filtersUrlMethod="filtersUrlMethod"
      :startWithRequest="filtersStartWithRequest"
      :sendFilterRequestAfterSelect="sendFilterRequestAfterSelect"
      :setEnabledFilters="filtersItems"
      :enable-save-csv="enableSaveCsv"
      :save-csv-url="saveCsvUrl"
      :show-collapse-filter="wrapperFiltersData.showCollapseFilter"
      :show-reset-filter-button="wrapperFiltersData.showResetFilterButton"
      :wrapper-load-color="wrapperFiltersData.wrapperLoadColor"
      :show-alert-message="this.showAlertMessage"
      @set-filtered-list="setFilteredList"
      accept-filters-button-label="Apply filters"
      show-filters-button-label="Show filters"
      minimize-filters-button-label="Minimize"
      reset-filters-button-label="Reset"
    ></wrapper-filters-component>
    <vue-good-table
      :title="tableTitle"
      :columns="prepareColumns"
      :rows="baseTableListToShow"
      :lineNumbers="tableLineNumbers"
      :sort-options="{
              enabled: tableSortOptionEnabled,
            }"
      :style-class="'vgt-table admin-table'"
      :globalSearch="true"
      :pagination-options="{
              enabled: enablePagination,
              perPage: perPage,
              rowsPerPageLabel: rowsPerPageText,
              nextLabel: nextText,
              prevLabel: prevText,
              page: paginationData.currentPage
            }"
    >
      <template v-slot:table-actions>
        <slot name="base-table-actions"
              :changeAllWithLoader="changeAllWithLoader"
              :toggleShowModalAdd="toggleShowModalAdd"
              :deleteAll="deleteAll"
        >
        </slot>
      </template>
      <template v-slot:table-row="props">
        <slot name="base-table-row"
              :changeItemWithLoader="changeItemWithLoader"
              :deleteById="deleteById"
              :row="props.row"
              :column="props.column"
              :formattedRow="props.formattedRow">
          <div class="admin-table-element" style="font-size: 16px">
            <template v-if="props.column.field === 'service'">
              <img :src="props.row['service_img_path']" alt="" width="20px">
              {{ props.formattedRow[props.column.field] }}
            </template>
            <template v-else-if="props.column.field === 'country'">
              <img :src="props.row['country_img_path']" alt="" width="20px">
              {{ props.formattedRow[props.column.field] }}
            </template>
            <template v-else-if="props.column.field === 'wholesale_price'">
              <input @keyup.enter="changeItemWithLoader(props.row['id'], {wholesale_price: $event.target.value})"
                     type="text"
                     :value="props.formattedRow[props.column.field]"
                     :placeholder="props.formattedRow[props.column.field]"
              >
            </template>
            <template v-else-if="props.column.field === 'service_code'">
              <input @keyup.enter="changeItemWithLoader(props.row['id'], {service_code: $event.target.value})"
                     type="text"
                     :value="props.formattedRow[props.column.field]"
                     :placeholder="props.formattedRow[props.column.field]"
              >
            </template>
            <template v-else-if="props.column.field === 'country_code'">
              <input @keyup.enter="changeItemWithLoader(props.row['id'], {country_code: $event.target.value})"
                     type="text"
                     :value="props.formattedRow[props.column.field]"
                     :placeholder="props.formattedRow[props.column.field]"
              >
            </template>
            <template v-else-if="props.column.field === 'ratio'">
              <input @keyup.enter="changeItemWithLoader(props.row['id'], {ratio: $event.target.value})"
                     type="text"
                     :value="props.formattedRow[props.column.field]"
                     :placeholder="props.formattedRow[props.column.field]"
              >
            </template>
            <template v-else-if="props.column.field === 'is_active'">
              <button v-if="props.formattedRow[props.column.field]" class="btn btn-success w-100"
                      @click="changeItemWithLoader(props.row['id'], {is_active: false})">ON
              </button>
              <button v-else class="btn btn-danger w-100"
                      @click="changeItemWithLoader(props.row['id'], {is_active: true})">OFF
              </button>
            </template>
            <template v-else-if="props.column.field === 'allow_multiple_sms'">
              <button v-if="props.formattedRow[props.column.field]" class="btn btn-success w-100"
                      @click="changeItemWithLoader(props.row['id'], {allow_multiple_sms: false})">ON
              </button>
              <button v-else class="btn btn-danger w-100"
                      @click="changeItemWithLoader(props.row['id'], {allow_multiple_sms: true})">OFF
              </button>
            </template>
            <template v-else>
              {{ props.formattedRow[props.column.field] }}
            </template>
          </div>
        </slot>
      </template>
      <template v-slot:pagination-bottom="props" v-if="useCustomPagination">
        <slot name="base-table-pagination-bottom">
          <custom-pagination
            :total="props.total"
            :pageChanged="props.pageChanged"
            :perPageChanged="props.perPageChanged"
            :perPage="perPage"
          >
          </custom-pagination>
        </slot>
      </template>
      <template v-slot:table-actions-bottom>
        <slot name="base-table-actions-bottom">
        </slot>
      </template>

    </vue-good-table>
  </div>
</template>

<script>
import CustomPagination from "./customPagination.vue";
import BaseAdminMixin from "../admin/components/mixins/BaseAdminMixin.vue";
import ModalAdd from './modalAdd.vue';
import WrapperFiltersComponent from "./wrapperFilters.vue";


export default {
  name: "baseTableComponent",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    CustomPagination,
    WrapperFiltersComponent,
    ModalAdd,
  },
  props: {
    columns: {
      type: Array,
      default: []
    },
    wrapperFiltersData: {
      type: Object,
      default: {}
    },
    addModalInputData: {
      type: Object,
      default: null,
    },
    perPage: {
      type: Number,
      default: 25
    },
    renderListToShow: {
      type: Function,
      required: true
    },
    tableLineNumbers: {
      type: Boolean,
      default: false
    },
    tableSortOptionEnabled: {
      type: Boolean,
      default: true
    },
    tableTitle: {
      type: String,
      default: 'Table title'
    },
    rowsPerPageText: {
      type: String,
      default: 'Rows per page'
    },
    nextText: {
      type: String,
      default: 'Next'
    },
    prevText: {
      type: String,
      default: 'Previous'
    },
    enablePagination: {
      type: Boolean,
      default: true
    },
    deleteAllUrl: {type: String},
    deleteAllMethod: {
      type: String,
      default: 'delete'
    },
    deleteByIdUrl: {type: String},
    deleteByIdMethod: {
      type: String,
      default: 'delete'
    },
    changeItemWithLoaderMethod: {type: String},
    changeItemWithLoaderUrl: {type: String},
    changeAllWithLoaderMethod: {type: String},
    changeAllWithLoaderUrl: {type: String},
    saveCsvUrl: {
      type: String
    },
    enableSaveCsv: {
      type: Boolean,
      default: false
    },
    useCustomPagination: {
      type: Boolean,
      default: false
    },
    showAlertMessage: {
      type: Boolean,
      default: true
    },
  },
  async created() {
    this.extractFiltersData();
    this.prepareColumnsSortFn();
    await this.prepareInputData();
  },

  data() {
    return {
      filteredList: [],
      prepareColumns: [],
      renderList: [],
      enableModalAdd: false,
      enabledFilters: false,
      getListArr: [],
      addModalUrl: null,
      filterTitle: '',
      filtersUrl: '',
      filtersUrlMethod: 'get',
      filtersItems: {},
      filtersStartWithRequest: false,
      sendFilterRequestAfterSelect: false,
      paginationData: {
        total: 0,
        perPage: 300,
        currentPage: 0,
        lastPage: 0,
        isPaginationLoaded: false
      },
    }
  },
  mounted() {
    this.$root.$on('add-item', (data) => {
      this.pasteNewAddModalItems(data)
    })
    this.$root.$on('update-item', (data) => {
      this.updateFilteredListById(data)
    })
  },
  methods: {
    async prepareInputData() {
      if (this.addModalInputData) {
        if (this.addModalInputData.addModalUrl) {
          this.addModalUrl = this.addModalInputData.addModalUrl;
        }
        if (this.addModalInputData.items) {
          await this.addGetListItems(this.addModalInputData.items);
          this.enableModalAdd = true
        }
      }
      if (this.wrapperFiltersData.sendFilterRequestAfterSelect) {
        this.sendFilterRequestAfterSelect = true;
      }
      if (this.wrapperFiltersData.items) {
        await this.addGetListItems(this.wrapperFiltersData.items);
        this.enabledFilters = true;
      } else if (this.wrapperFiltersData.startWithRequest) {
        this.enabledFilters = true;
      }
    },
    prepareColumnsSortFn() {
      this.prepareColumns = this.columns.map(el => {
        if (el.sortFn && typeof this[el.sortFn] === 'function') {
          el.sortFn = this[el.sortFn]
        }
        return el;
      });
    },
    async addGetListItems(items) {
      for (let item in items) {
        if (items[item].getDataLink !== undefined) {
          let getListArrItem = this.getListArr.find((el) => el.link === items[item].getDataLink);
          if (!getListArrItem) {
            let list = await this.sendRequest(items[item].getDataLink);
            let obj = {
              link: items[item].getDataLink,
              data: list
            }
            this.getListArr.push(obj);
            items[item]['data'] = list;
          } else {
            items[item]['data'] = getListArrItem.data;
          }
        }
      }
    },
    extractFiltersData() {
      this.filterTitle = this.wrapperFiltersData.title;
      this.filtersUrl = this.wrapperFiltersData.filtersUrl;
      this.filtersItems = this.wrapperFiltersData.items
      if (this.wrapperFiltersData.startWithRequest) {
        this.filtersStartWithRequest = this.wrapperFiltersData.startWithRequest
      }
    },
    setFilteredList(response) {
      this.filteredList = response;
    },
    pasteNewAddModalItems(data) {
      this.filteredList.unshift(...data);
    },
    toggleShowModalAdd() {
      if (this.$refs.modalComponent) {
        this.$refs.modalComponent.toggleShowModalAdd();
      }
    },

    async changeItemWithLoader(id, params) {
      let index = this.getIndexByProp(this.filteredList, 'id', id);
      let newObj = Object.assign({}, this.filteredList[index], params);
      let cloneObj = [...this.filteredList];
      await this.doWithStopRequest(() => this.doWithLoader(async () => {
        let response = await this.sendRequest(
          this.changeItemWithLoaderUrl,
          this.changeItemWithLoaderMethod,
          newObj
        );
        if (response) {
          this.filteredList = [];
          await this.alertMessage(
            'Item has been changed',
            'info'
          );
          this.filteredList = [...cloneObj];
          this.filteredList[index] = Object.assign({}, newObj)
        }
      }), 'changeItem')
    },
    async changeAllWithLoader(data) {
      await this.doWithStopRequest(() => this.doWithLoader(async () => {
        let filteredServiceVariationsIds = this.filteredList.map(function (elem) {
          return elem['id'];
        });

        if (confirm('Вы уверены что хотите поменять ' + filteredServiceVariationsIds.length + ' вариаций?')) {
          let params = data;
          params['ids'] = filteredServiceVariationsIds;
          let response = await this.sendRequest(
            this.changeAllWithLoaderUrl,
            this.changeAllWithLoaderMethod,
            params
          );

          if (response) {
            this.filteredList = this.filteredList.map(function (elem) {
              elem = Object.assign(elem, data);
              return elem;
            });
            await this.alertMessage(
              'Items have been changed',
              'info'
            );
          }
        }
      }), 'Изменение сервисов')
    },
    getIndexByProp(list, propKey, propValue) {
      for (let key in list) {
        if (list[key][propKey] == propValue) {
          return key;
        }
      }
      return null;
    },
    sortNumber(a, b) {
      let x = Number(a);
      let y = Number(b);
      return (x < y ? -1 : (x > y ? 1 : 0));
    },
    async deleteAll() {
      await this.doWithStopRequest(() => this.doWithLoader(async () => {
        let ids = this.filteredList.map(function (elem) {
          return elem['id'];
        });

        if (confirm('Вы уверены что хотите удалить ' + ids.length + ' вариаций?')) {
          let response = await this.sendRequest(
            this.deleteAllUrl,
            this.deleteAllMethod,
            {
              ids: ids
            }
          );

          if (response) {
            this.filteredList = [];
            await this.alertMessage(
              'Items have been deleted',
              'info'
            );
          }
        }
      }), 'Удаление')
    },

    async deleteById(id) {
      await this.doWithStopRequest(() => this.doWithLoader(async () => {
        let response = await this.sendRequest(
          this.deleteByIdUrl,
          this.deleteByIdMethod,
          {
            id: id
          }
        );
        if (response) {
          let index = this.getIndexByProp(this.filteredList, 'id', id);
          this.filteredList.splice(index, 1);
          await this.alertMessage(
            'Item has been deleted',
            'info'
          );
        }
      }), 'Удаление')
    },
    async updateFilteredListById(data){
      this.filteredList = this.filteredList.map(function (elem) {
        if(elem.id == data.id){
          elem = Object.assign(elem, data);
        }
        return elem;
      });
    }
  },
  computed: {
    baseTableListToShow() {
      return this.renderListToShow(this.filteredList);
    }
  }
}
</script>

<style>
.subnav__sl {
  z-index: 1;
}
</style>
