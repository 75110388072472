<template>
  <div>
    <base-table
      :render-list-to-show="this.listToShow"
      :per-page="25"
      :wrapper-filters-data="this.wrapperFiltersData"
      :columns="this.columns"
      :enable-save-csv="true"
      saveCsvUrl ="/statistic/topSales/downloadCsvUserStatistic"
    >
    </base-table>
  </div>
</template>

<script>
import BaseTable from "../../../components/baseTable.vue";
export default {
  name: "topSalesStatistic",
  components: {BaseTable},
  data: function () {
    return {
      wrapperFiltersData: {
        title: "Статистика по топ продажам",
        filtersUrl: "/statistic/topSales/getStat",
        items: {
          date: {
            enable: true,
            column: 1,
          },
          dateSelector: {
            enable: true,
            placeholder: 'Период',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'dateSelector',
            data: [
              {
                id: 3,
                title: 'За последний день'
              },
              {
                id: 4,
                title: 'За последние 7 дней'
              },
              {
                id: 5,
                title: 'За последние 30 дней'
              },
            ],
            column: 1,
          },
          countries: {
            enable: true,
            multiple: true,
            getDataLink: '/countries/getList',
            placeholder: 'Страны',
            viewLabel: 'titleWithId',
            column: 2,
          },
          services: {
            enable: true,
            multiple: true,
            getDataLink: '/services/getList',
            placeholder: 'Сервисы',
            viewLabel: 'titleWithId',
            column: 2
          },
        },
      },
      columns: [
        {
          label: 'Страна',
          field: 'country',
          width: '15%',
          sortable: false,
        },
        {
          label: 'Сервис',
          field: 'service',
          width: '15%',
          sortable: false,
        },
        {
          label: 'Кол-во выданных номеров',
          field: 'count_received',
          type: 'number',
          width: '15%',
          sortable: true,
        },
        {
          label: 'Смс отдано',
          field: 'count_success',
          type: 'number',
          width: '11%',
          sortable: true,
        },
        {
          label: 'Доставка',
          field: 'delivery',
          sortable: true,
          type: 'number',
          width: '10%'
        },
        {
          label: 'Средняя цена продажи',
          field: 'average_selling_price',
          sortable: true,
          type: 'number',
          width: '10%'
        },
        {
          label: 'Выручка',
          field: 'income',
          type: 'number',
          sortable: true,
          width: '10%',
        },
        {
          label: 'Прибыль',
          field: 'profit',
          type: 'number',
          sortable: true,
          width: '10%'
        },
        {
          label: 'Маржа',
          field: 'margin',
          type: 'number',
          sortable: true,
          width: '11%'
        },
      ],
    }
  },
  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        data.push({
          average_selling_price: Number(elem['average_selling_price']).toFixed(2),
          count_received: Number(elem['count_received']).toFixed(0),
          count_success: Number(elem['count_success']).toFixed(0),
          delivery: Number(elem['delivery']).toFixed(2) + '%',
          income: Number(elem['income']).toFixed(2),
          profit: Number(elem['profit']).toFixed(2),
          margin: Number(elem['margin']).toFixed(2) + '%',
          country: elem['provider_service_variation']['service_variation']['country']['title'] + '(' + elem['provider_service_variation']['service_variation']['country']['id'] + ')',
          country_img_path: elem['provider_service_variation']['service_variation']['country']['img_path'],
          service: elem['provider_service_variation']['service_variation']['service']['title'] + '(' + elem['provider_service_variation']['service_variation']['service']['id'] + ')',
          service_img_path: elem['provider_service_variation']['service_variation']['service']['img_path'],
          avg_sms_arrival: Number(elem['avg_sms_arrival']).toFixed(0),
        });
      }
      return data;
    }
  }
}
</script>
