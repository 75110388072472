<template>
  <div>
    <base-table
        :columns="this.columns"
        :per-page="perPage"
        :wrapperFiltersData="this.wrapperFiltersData"
        :addModalInputData="this.addModalInputData"
        :changeItemWithLoaderMethod="changeItemWithLoaderMethod"
        :changeItemWithLoaderUrl="changeItemWithLoaderUrl"
        :render-list-to-show="this.listToShow"
    >
      <template v-slot:base-table-actions="props">
        <div class="fx">
          <button class="btn-t btn--borderColor flex-center" v-on:click="props.toggleShowModalAdd">Добавить сервис</button>
        </div>
      </template>
      <template v-slot:base-table-row="props">
        <div class="admin-table-element">
          <template v-if="props.column.field === 'title'">
            <img :src="props.row['service_img_path']" alt="" width="20px">
            <input
                @keyup.enter="props.changeItemWithLoader(props.row['id'], {title: $event.target.value})"
                type="text"
                :value="props.formattedRow[props.column.field]"
                :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'ksms_id'">
            <input @keyup.enter="props.changeItemWithLoader(props.row['id'], {ksms_id: $event.target.value})"
                   type="text"
                   :value="props.formattedRow[props.column.field]"
                   :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'sms_activate_org_code'">
            <input
                @keyup.enter="props.changeItemWithLoader(props.row['id'], {sms_activate_org_code: $event.target.value})"
                type="text"
                :value="props.formattedRow[props.column.field]"
                :placeholder="props.formattedRow[props.column.field]"
            >
          </template>
          <template v-else-if="props.column.field === 'is_active'">
            <button v-if="props.row['is_active']" class="btn btn-success w-90"
                    @click="props.row['is_active'] = 0; props.changeItemWithLoader(props.row['id'], {is_active: false})">ДА
            </button>
            <button v-else class="btn btn-danger w-90"
                    @click="props.row['is_active'] = 1; props.changeItemWithLoader(props.row['id'], {is_active: true})">НЕТ
            </button>
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import CustomPagination from "../../../components/customPagination";
import BaseAdminMixin from "../mixins/BaseAdminMixin";
import Modal from '../../../components/modal';
import BaseTable from "../../../components/baseTable.vue";

export default {
  name: "serviceEditTable",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    CustomPagination,
    Modal,
    BaseTable
  },
  data: function () {
    return {
      filteredList: [],
      wrapperFiltersData: {
        title: "Сервисы",
        startWithRequest: true,
        filtersUrl: "/services/getList",
        items:{
          services: {
            enable: true,
            multiple: true,
            getDataLink: '/services/getList',
            placeholder: 'Сервисы',
            viewLabel: 'titleWithId',
            column: 1
          }
        }
      },
      addModalInputData: {
        addModalTitle: 'Добавить',
        addModalUrl: '/services/add',
        addModalMethod: 'post',
        items:{
          title: {
            simpleInputType: 'text',
            placeholder: 'Название',
            selectedKey: 'title',
          }
        }
      },
      changeItemWithLoaderUrl: '/services/',
      changeItemWithLoaderMethod: 'patch',
      columns: [
        {
          label: 'ID',
          field: 'id',
          sortFn: this.sortNumber,
          width: '15%',
          html: true
        },
        {
          label: 'Название',
          field: 'title',
          width: '30%',
          html: true
        },
        {
          label: 'ksms_id',
          field: 'ksms_id',
          width: '15%',
          html: true
        },
        {
          label: 'ID смс активейтов',
          field: 'sms_activate_org_code',
          width: '25%',
          html: true
        },
        {
          label: 'Активность',
          field: 'is_active',
          width: '15%',
          html: true
        },
        {
          label: 'Поиск',
          field: 'name_search',
          width: '25%',
        },
      ],
      filters: {},
      perPage: 500,
      showModalAdd: false,
      paramsToAdd: {
        title: null,
      },
    };
  },

  methods: {
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        data.push({
          id: elem['id'],
          title: elem['title'],
          service_img_path: elem['img_path'],
          ksms_id: elem['ksms_id'],
          sms_activate_org_code: elem['sms_activate_org_code'],
          is_active: elem['is_active'],
          name_search: elem['alternative_params']?.name_search,
        });
      }
      return data;
    }
  },
}
</script>

<style scoped>

</style>
